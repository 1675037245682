import Swal from 'sweetalert2';

export const Toast = (position, large, showConfirmButton = false) => {
    return Swal.mixin({
        toast: true,
        position: position,
        padding: large ? '2em' : '1em',
        showConfirmButton: showConfirmButton,
        timer: !showConfirmButton && 6000,
        timerProgressBar: !showConfirmButton,
        target: document.getElementById('root'),
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
}

export const TermToast = (position, large) => {
    return Swal.mixin({
        toast: true,
        position: position,
        padding: large ? '2em' : '1em',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'EVET',
        denyButtonText: 'HAYIR',
        cancelButtonText: 'İPTAL',
        target: document.getElementById('root'),
    });
}