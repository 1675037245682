// assets
import { IconDashboard } from '@tabler/icons';

// constant
const icons = { 
    IconDashboard
 };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //
const dashboard = {
    id: 'dashboard',
    // title: 'İBB İştirakler Mali Raporlama Yönetim Sistemi',
    type: 'group',
    children: [
        {
            id: 'default',  
            title: 'Kontrol Paneli',
            type: 'item',
            url: '/',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
    ]
};

export default dashboard;