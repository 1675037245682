import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

// project imports
import Loadable from 'ui-component/Loadable';

// login routing
const Login = Loadable(lazy(() => import('views/authentication/Login')));
const ResetPassword = Loadable(lazy(() => import('views/authentication/ResetPassword')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //
const AuthenticationRoutes = {
    path: '/',
    element: <Outlet />,
    children: [
        {
            path: 'login',
            element: <Login />
        },
        {
            path: 'reset-password',
            element: <ResetPassword />
        },
    ]
};

export default AuthenticationRoutes;
