import axios from '../utils/axios';

export const Create = async (userName, name, surname, phoneNumber, emailAddress, isActive, roleNames, password,titleId,tenantId) => {
    try {
        return await axios.post("/api/services/app/User/Create", { userName, name, surname, phoneNumber, emailAddress, isActive, roleNames, password,titleId,tenantId })
    } catch (error) {
        console.error('API hatası:', error);
        return error.response.data;
    }
};

export const Update = async (id, userName, name, surname, phoneNumber, emailAddress, isActive, fullName, lastLoginTime, creationTime, roleNames,titleId,tenantId) => {
    try {
        return await axios.put("/api/services/app/User/Update", { id, userName, name, surname, phoneNumber, emailAddress, isActive, fullName, lastLoginTime, creationTime, roleNames,titleId,tenantId })
    } catch (error) {
        console.error('API hatası:', error);
        return error.response.data;
    }
};

export const Delete = async (id) => {
    try {
        return await axios.delete("/api/services/app/User/Delete?Id=" + id)
    } catch (error) {
        console.error('API hatası:', error);
        return error.response.data;
    }
};

export const Activate = async (id) => {
    try {
        return await axios.post("/api/services/app/User/Activate", { id })
    } catch (error) {
        console.error('API hatası:', error);
        return error.response.data;
    }
};

export const DeActivate = async (id) => {
    try {
        return await axios.post("/api/services/app/User/DeActivate", { id })
    } catch (error) {
        console.error('API hatası:', error);
        return error.response.data;
    }
};

export const GetRoles = async () => {
    try {
        return await axios.get("/api/services/app/User/GetRoles")
    } catch (error) {
        console.error('API hatası:', error);
        return error.response.data;
    }
};

export const ChangeLanguage = async (languageName) => {
    try {
        return await axios.post("/api/services/app/User/ChangeLanguage", { languageName })
    } catch (error) {
        console.error('API hatası:', error);
        return error.response.data;
    }
};

export const GetAll = async (Keyword = "", IsActive = "", SkipCount = 0, MaxResultCount = 10) => {
    try {
        return await axios.get("/api/services/app/User/GetAll?Keyword=" + Keyword + "&IsActive=" + IsActive + "&SkipCount=" + SkipCount + "&MaxResultCount=" + MaxResultCount);
    } catch (error) {
        console.error("API hatası:", error);
        return error.response.data;
    }
};

export const ChangePassword = async (currentPassword, newPassword) => {
    try {
        return await axios.post("/api/services/app/User/ChangePassword", { currentPassword, newPassword })
    } catch (error) {
        console.error('API hatası:', error);
        return error.response.data;
    }
};

export const ResetPassword = async (adminPassword, userId, newPassword) => {
    try {
        return await axios.post("/api/services/app/User/ResetPassword", { adminPassword, userId, newPassword })
    } catch (error) {
        console.error('API hatası:', error);
        return error.response.data;
    }
};

export const GetAllUserWithTitleList = async () => {
    try {
        return await axios.get("/api/services/app/User/GetAllUserWithTitleList")
    } catch (error) {
        console.error('API hatası:', error);
        return error.response.data;
    }
};

export const GetAllNotTenantUsers = async () => {
    try {
        return await axios.get("/api/services/app/User/GetAllNotTenantUsers")
    } catch (error) {
        console.error('API hatası:', error);
        return error.response.data;
    }
};

export const Get = async (id) => {
    try {
        return await axios.get("/api/services/app/User/Get?Id=" + id)
    } catch (error) {
        console.error('API hatası:', error);
        return error.response.data;
    }
};

export const GetAllTenantUsers = async (tenantId) => {
    try {
        return await axios.get("/api/services/app/User/GetAllTenantUsers?tenantId=" + tenantId)
    } catch (error) {
        console.error('API hatası:', error);
        return error.response.data;
    }
};