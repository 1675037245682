// assets
import { IconUpload, IconCalendar } from '@tabler/icons';

// constant
const icons = {
    IconUpload,
    IconCalendar
};

// ==============================|| STANDARD MENU ITEMS ||============================== //
const standard = {
    id: 'pages',
    // title: 'Gruplama1',
    type: 'group',
    children: [
        {
            id: 'veri-yukleme',
            title: 'Veri Yükle',
            type: 'item',
            url: '/veri-yukleme',
            permission: 'Pages.FileUpload',
            icon: icons.IconUpload,
            breadcrumbs: false
        },
        {
            id: 'donem-yonetimi',
            title: 'Dönem Aç',
            type: 'item',
            url: '/donem-yonetimi',
            permission: 'Pages.Terms',
            icon: icons.IconCalendar,
            breadcrumbs: false
        },

    ]

};

export default standard;