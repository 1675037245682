import axios from "../utils/axios";

export const GetCurrentLoginInformations = async () => {
    try {
      return await axios.get("/api/services/app/Session/GetCurrentLoginInformations");
    } catch (error) {
      console.error("API hatası:", error);
      return error.response.data;
    }
};

export const GetCurrentUserPermissions = async () => {
    try {
      return await axios.get("/api/services/app/Session/GetCurrentUserPermissions");
    } catch (error) {
      console.error("API hatası:", error);
      return error.response.data;
    }
};