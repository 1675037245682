// material-ui
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

// ==============================|| TITLE ||============================== //
const TitleSection = () => {
    const theme = useTheme();

    const themeOptions = {
        typography: {
            h1: {
                fontSize: 18,
                fontWeight: 'lighter',
                fontKerning: 'normal'
            },
        },
    };

    const newTheme = createTheme(themeOptions);

    return (
        <ThemeProvider theme={newTheme}>
            <Typography
                variant='h1'
                sx={{
                    alignItems: 'center',
                    color: theme.palette.secondary.main
                }}
            >
                İBB İştirakler Mali Raporlama Yönetim Sistemi
            </Typography>
            {/* <Chip 
                label={<Typography variant='h1'>İBB İştirakler Mali Raporlama Yönetim Sistemi</Typography>}
                // component="a" href="/" clickable 
                variant="outlined"
                sx={{
                    mx: 'auto',
                    width: 500,
                    maxWidth: '100%',
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    color: theme.palette.secondary.main,
                    borderColor: theme.palette.secondary.main,
                    backgroundColor: theme.palette.primary.light,
                    ' &:hover': {
                        color: theme.palette.primary.main,
                        borderColor: theme.palette.primary.main,
                        backgroundColor: theme.palette.primary.light,
                    }
                }}
            /> */}
        </ThemeProvider>
    );
};

export default TitleSection;