import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';


// ==============================|| APP.JS ||============================== //

const App = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const customization = useSelector((state) => state.customization);

    useEffect(() => {
        if (!sessionStorage.getItem("token") && location.pathname !== '/reset-password') {
            navigate("/login");
        }
    }, [])

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;