import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from 'config';
import { MENU_OPEN } from 'store/actions';

import ibb_logo from 'ui-component/ibb-istanbul-logo.png'

// ==============================|| MAIN LOGO ||============================== //
const LogoSection = () => {
    const defaultId = useSelector((state) => state.customization.defaultId);
    const dispatch = useDispatch();
    return (
        <ButtonBase disableRipple onClick={() => dispatch({ type: MENU_OPEN, id: defaultId })} component={Link} to={config.defaultPath}>
            <img src={ibb_logo} alt="Logo" style={{ width: 177.32, height: 25.74, objectFit: "contain" }}/>
        </ButtonBase>
    );
};

export default LogoSection;