import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

// project imports
import Loadable from 'ui-component/Loadable';

// error routing
const Error = Loadable(lazy(() => import('views/errors')));

// ==============================|| ERROR ROUTING ||============================== //
const ErrorRoutes = {
    path: '/',
    element: <Outlet />,
    children: [
        {
            path: '/errors/error-500',
            element: <Error code={500}/>
        },
        {
            path: '/errors/error-503',
            element: <Error code={503}/>
        },
        {
            path: '*',
            element: <Error />
        }
    ]
};

export default ErrorRoutes;
