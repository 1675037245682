// assets
import { IconHelp } from '@tabler/icons';

// constant
const icons = { IconHelp };

// ==============================|| F.A.Q PAGE MENU ITEMS ||============================== //
const other = {
    id: 'others',
    type: 'group',
    children: [
        {
            id: 'faq-page',
            title: 'S.S.S',
            type: 'item',
            url: '/sik-sorulan-sorular',
            icon: icons.IconHelp,
            breadcrumbs: false
        },
    ]
};

export default other;