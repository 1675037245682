import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// pages routing
const Dashboard = Loadable(lazy(() => import('views/dashboard')));
const Tenants = Loadable(lazy(() => import('views/pages/tenants')));
const TenantGroups = Loadable(lazy(() => import('views/pages/tenants/TenantGroups')));
const TenantInfo = Loadable(lazy(() => import('views/pages/tenants/Tenant')));
const Users = Loadable(lazy(() => import('views/pages/users')));
const UserRoles = Loadable(lazy(() => import('views/pages/users/UserRoles')));
const FileUpload = Loadable(lazy(() => import('views/pages/file-upload')));
const TermManagement = Loadable(lazy(() => import('views/pages/term-management')));
const MailTemplates = Loadable(lazy(() => import('views/pages/mails/MailTemplates')));
const MailReceivers = Loadable(lazy(() => import('views/pages/mails/MailReceivers')));
const Validations = Loadable(lazy(() => import('views/pages/templates/validations')));
const Templates = Loadable(lazy(() => import('views/pages/templates')));
const FaqPage = Loadable(lazy(() => import('views/pages/faq-page')));
const Settings = Loadable(lazy(() => import('views/pages/settings')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: 'istirakler',
            element: <Tenants />
        },
        {
            path: 'istirak',
            element: <TenantInfo />
        },
        {
            path: 'istirak-gruplari',
            element: <TenantGroups />
        },
        {
            path: 'kullanicilar',
            element: <Users />
        },
        {
            path: 'roller',
            element: <UserRoles />
        },
        {
            path: 'veri-yukleme',
            element: <FileUpload />
        },
        {
            path: 'donem-yonetimi',
            element: <TermManagement />
        },
        {
            path: 'mail-sablonlari',
            element: <MailTemplates />
        },
        {
            path: 'mail-alicilari',
            element: <MailReceivers />
        },
        {
            path: 'sablonlar',
            element: <Templates />
        },
        {
            path: 'validasyon-yonetimi',
            element: <Validations />
        },
        {
            path: 'sik-sorulan-sorular',
            element: <FaqPage />
        },
        {
            path: 'ayarlar',
            element: <Settings />
        }
    ]
};

export default MainRoutes;