import React, { useState, useEffect } from 'react';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { Toast } from 'ui-component/extended/Toast';
import * as userApi from 'api/UserApi';
import * as sessionApi from 'api/SessionApi';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    // const [loading, setLoading] = useState(true);
    // const [permissions, setPermissions] = useState([]);

    // async function loadData() {
    //     setLoading(true);
    //     await sessionApi.GetCurrentUserPermissions()
    //         .then(response => {
    //             if (response.success)
    //                 setPermissions(response.result.grantedPermissions);
    //             else
    //                 Toast('center', true, true).fire({
    //                     icon: 'error',
    //                     title: response.error.message,
    //                     text: response.error.details
    //                 })
    //         });
    //     setLoading(false);
    // }

    // useEffect(() => {
    //     loadData();
    // }, []);

    // console.log(permissions)

    const navItems = menuItem.items.map((item) => {
        // switch (item.type) {
        //     case 'group':
        //         return <NavGroup key={item.id} item={item} />;
        //     default:
        //         return (
        //             <Typography key={item.id} variant="h6" color="error" align="center">
        //                 Menu Error
        //             </Typography>
        //         );
        // }
        // console.log(item)
        // console.log(item.children)
        // console.log(item.children?.children)
        if (item.type === 'group' || item.children.type === 'collapse') {
            return <NavGroup key={item.id} item={item} />;
        }
        // if (item.children) {
        //     return item.children?.map((children) => {
        //         if (item.type === 'group' && children.type === 'item' && children.permission) {
        //             console.log(children.permission);
        //             return <NavGroup key={item.id} item={item} />;
        //         }
        //         if (item.type === 'group' && children.type !== 'item') {
        //             return children.children.map((child) => {
        //                 if (item.type === 'group' && child.type === 'item' && child.permission) {
        //                     console.log(child.permission);
        //                     return <NavGroup key={item.id} item={item} />;
        //                 }
        //             });
        //         }

        //     });
        // }
        // if (item.children?.children) {
        //     console.log(item.children?.children)
        //     return item.children.children?.map((child) => {
        //         if (item.type === 'group' && child.type === 'item' && child.permission) {
        //             console.log(child.permission);
        //             return <NavGroup key={item.id} item={item} />;
        //         }
        //     });
        // }

        else {
            return (
                <Typography key={item.id} variant="h6" color="error" align="center">
                    Menu Error
                </Typography>
            );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
