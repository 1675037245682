import dashboard from './dashboard';
import standard from './standard';
import pages from './pages';
import other from './other';

// ==============================|| MENU ITEMS ||============================== //
const menuItems = {
    items: [dashboard, standard, pages, other]
};

export default menuItems;