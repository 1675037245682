import axios from 'axios';
import { apiURL } from './config';

const instance = axios.create({
    baseURL: apiURL
});

instance.interceptors.request.use((config) => {
    config.headers.Authorization = (sessionStorage.getItem('token') == null) ? null : `Bearer ${sessionStorage.getItem('token')}`;
    config.headers['Content-Type'] = "application/json";
    config.headers['Access-Control-Allow-Origin'] = "*";
    config.headers['Access-Control-Allow-Credentials'] = "true";
    config.headers['Access-Control-Allow-Headers'] = "Origin, X-Requested-With, Content-Type, Accept";
    return config;
});

instance.interceptors.response.use(
    (response) => response.data,
    (error) => {
        console.error(error);
        if (error?.response?.status === 401) {
            window.location.href = '/';
            return;
        } else if (error?.response?.status === 500 && !error?.response?.data?.error?.message) {
            // sessionStorage.setItem('error500Text', JSON.stringify(error.response.config.url));
            window.location.href = '/errors/error-500/';
            return;
        } else if (error?.response?.status === 503) {
            // sessionStorage.setItem('ErrorMessage503', error?.response?.data['Error']);
            window.location.href = '/errors/error-503/';
            return;
        }
        // TODO: log server errors exept 401
        return Promise.reject(error);
    }
);

export default instance;