// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

export const pageSize = 10;

export const monthArray = [
    { "id": 1, "name": "Ocak" },
    { "id": 2, "name": "Şubat" },
    { "id": 3, "name": "Mart" },
    { "id": 4, "name": "Nisan" },
    { "id": 5, "name": "Mayıs" },
    { "id": 6, "name": "Haziran" },
    { "id": 7, "name": "Temmuz" },
    { "id": 8, "name": "Ağustos" },
    { "id": 9, "name": "Eylül" },
    { "id": 10, "name": "Ekim" },
    { "id": 11, "name": "Kasım" },
    { "id": 12, "name": "Aralık" }
];

export const monthNames = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];
