// assets
import { IconActivity, IconLicense, IconBuildingSkyscraper, IconUsers, IconDeviceAnalytics, IconMail,IconSettings } from '@tabler/icons';

// constant
const icons = {
    IconActivity,
    IconLicense,
    IconBuildingSkyscraper,
    IconUsers,
    IconDeviceAnalytics,
    IconMail,
    IconSettings
};

// ==============================|| PAGES MENU ITEMS ||============================== //
const pages = {
    id: 'pages',
    // title: 'Gruplama2',
    type: 'group',
    children: [
        // {
        //     id: 'son-hareketler',
        //     title: 'Son Hareketler',
        //     type: 'item',
        //     url: '/son-hareketler',
        //     icon: icons.IconActivity,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'performans-degerlendirmesi',
        //     title: 'Performans Durumu',
        //     type: 'item',
        //     url: '/performans-degerlendirmesi',
        //     icon: icons.IconDeviceAnalytics,
        //     breadcrumbs: false
        // },
        {
            id: 'istirak-tanimlari',
            title: 'İştirak Tanımları',
            type: 'collapse',
            icon: icons.IconBuildingSkyscraper,
            children: [
                {
                    id: 'istirakler',
                    title: 'İştirakler',
                    type: 'item',
                    url: '/istirakler',
                    permission: 'Pages.Tenants',
                    breadcrumbs: false
                },
                {
                    id: 'istirak-gruplari',
                    title: 'İştirak Grupları',
                    type: 'item',
                    url: '/istirak-gruplari',
                    permission: 'Pages.TenantGroups',
                    breadcrumbs: false
                },
            ]
        },
        {
            id: 'kullanici-yonetimi',
            title: 'Kullanıcı Yönetimi',
            type: 'collapse',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'kullanicilar',
                    title: 'Kullanıcılar',
                    type: 'item',
                    url: '/kullanicilar',
                    permission: 'Pages.Users',
                    breadcrumbs: false
                },
                {
                    id: 'roller',
                    title: 'Roller',
                    type: 'item',
                    url: '/roller',
                    permission: 'Pages.Roles',
                    breadcrumbs: false
                },
            ]
        },
        {
            id: 'mail-yonetimi',
            title: 'Mail Yönetimi',
            type: 'collapse',
            icon: icons.IconMail,
            children: [
                {
                    id: 'mail-sablonlari',
                    title: 'Mail Şablonları',
                    type: 'item',
                    url: '/mail-sablonlari',
                    permission: 'Pages.EmailTemplates',
                    breadcrumbs: false
                },
                {
                    id: 'mail-alicilari',
                    title: 'Mail Alıcıları',
                    type: 'item',
                    url: '/mail-alicilari',
                    permission: 'Pages.EmailReceivers',
                    breadcrumbs: false
                },
            ]
        },
        {
            id: 'sablon-yonetimi',
            title: 'Şablon Yönetimi',
            type: 'collapse',
            icon: icons.IconLicense,
            children: [
                {
                    id: 'sablonlar',
                    title: 'Şablonlar',
                    type: 'item',
                    url: '/sablonlar',
                    breadcrumbs: false
                },
                {
                    id: 'validasyon-yonetimi',
                    title: 'Validasyon Yönetimi',
                    type: 'item',
                    url: '/validasyon-yonetimi',
                    breadcrumbs: false
                },
            ]
        },
        {
            id: 'settings',
            title: 'Ayarlar',
            type: 'item',
            url: '/ayarlar',
            icon: icons.IconSettings,
            breadcrumbs: false
        },
    ]
};

export default pages;